.footer_inner {
  margin: 0 auto;
  min-height: 6vh;
  border-top: 1px solid $color-main;
  padding: 15px 0;

  @include md-min {
      min-height: 10vh;
  }
}
