.top-buttons {
  position: absolute;
  top: 20px;
  right: 20px;

  @include md-max {
    left: 20px;
  }
}

.top-button__item {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background-color: $color-main !important;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  color: $color-bg;
  font-weight: 500;
  font-size: 0.75rem;
  text-transform: uppercase;
  overflow: hidden;
  transition: color 0.3s ease;
  margin-right: 10px;

  @include md-min {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  &:hover {
    transform: scale(1.2);
  }
}

.top-button__insta {
  display: inline-block;

  @include md-min {
    margin-left: 5px;
  }
}

.instagram-icon {
  width: 15px;
  height: 15px;
  line-height: 20px;
  color: $color-bg;
  font-size: 1.2rem;
  text-align: center;
  vertical-align: middle;
  margin-top: -3px;

  @include md-min {
    width: 20px;
    height: 20px;
  }
}
