.instagram__images {
  display: flex;
  flex-direction: row;
  justify-items: space-between;
  gap: 5px;
  overflow: hidden;
  position: relative;

  @include xs-max {
    margin-left: -20px;
    margin-right: -20px;
    gap: 2px;
  }
}

.instagram__img {
  aspect-ratio: 1/1;
  height: 100%;
  object-fit: cover;
  height: 100%;
  width: calc(100% / 4);

  @include md-min {
    width: calc(100% / 6);
  }
}

.instagram__button {
  background-color: $color-bg;
  color: $color-dark;
  border: 2px solid $color-bg;
  padding: 15px 40px;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  &:hover {
    background-color: $color-main;
    border: 2px solid $color-main;
    color: $color-bg;
  }
}
