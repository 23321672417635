.about__inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: $color-bg;

  @include md-min {
    flex-direction: row;
  }
}

.about__bg {
  margin-top: -70px;
  background-color: $color-main;
  width: 95%;
  padding: 20px;
  border: 1px solid $color-bg;
  margin-left: -1px;

  @include md-min {
    width: 60%;
  }

  @include md-min {
    margin-top: 70px;
    margin-left: -50px;
    padding: 20px 30px;
  }

  @include lg-min {
    width: 50%;
  }
}

.about__img {
  width: 100%;
  max-height: 900px;
}

.about__title {
  font-family: "Parisienne", cursive;
  font-weight: lighter;
  font-size: 25px;
  color: $color-bg;
  margin-bottom: 10px;

  @include md-min {
    font-size: 30px;
  }
}

.about__subtitle {
  padding-bottom: 20px;
  font-weight: 400;
}

.about__description {
  line-height: 1.6;
}

.about__love-title {
  padding: 20px 0;

  @include md-min {
    padding: 30px 0;
  }
}

.about__love {
  display: flex;
  gap: 20px;
  padding: 20px 0;

  @include md-min {
    padding: 30px 0;
  }
}

.icon {
  font-size: 30px;
  color: $color-primary;
}

.about__love-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  span {
    padding: 15px 0;
    font-family: "Parisienne", cursive;
  }

  @include md-max {
    font-size: 15px;
  }
}

.about__description--italic {
  font-style: italic;
}

.about__description--heart {
  font-style: normal;
}
