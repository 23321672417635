.scroll-to-top {
  border-radius: 50%!important;
  color: $color-bg!important;
  border: 1px solid $color-bg!important;
  z-index:555555;
  animation: jump 1s ease-in-out infinite;
 background-color: $color-main!important;
  bottom: 50px!important;
  right: 20px!important;

  @include md-min {
    bottom: 100px!important;
  }
}

@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.jump-animation {
  animation: jump 1s ease-in-out infinite; 
}
