.services__cards {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 20px;

  @include md-min {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.services__card {
  width: 100%;
  @include md-min {
    width: calc(100% / 3);
  }
}

.services__card-img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  height: 100%;
}

.services__card-img--2 {
  object-position: center top;
}

.services__card-box {
  background-color: $color-main;
  color: $color-bg;
  padding: 30px 0px;
  text-align: center;
  letter-spacing: 4px;
}

.services__card__description {
  text-transform: uppercase;
}
