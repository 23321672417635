.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner-container {
  width: 50px;
  height: 50px;
  position: relative;
}

.spinner {
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top-color: $color-main;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
