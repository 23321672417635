@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(0.7) translateY(0);
    transform: scale(0.7) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1) translateY(-15px);
    transform: scale(1) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(0.7) translateY(0);
    transform: scale(0.7) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1) translateY(-15px);
    transform: scale(1) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
