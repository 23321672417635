.projects__composition {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0;
  margin-left: -5px;
  margin-right: -5px;
}

.big-img {
  grid-area: 1 / 1 / span 2 / span 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid #f1ebe4;

  @include md-min {
    border: 5px solid #f1ebe4;
  }
}

.medium-img {
  display: none;
}

.small-img-1 {
  grid-area: 1 / 2 / span 1 / span 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid #f1ebe4;

  @include md-min {
    border: 5px solid #f1ebe4;
  }
}

.small-img-2 {
  grid-area: 2 / 2 / span 1 / span 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid #f1ebe4;

  @include md-min {
    border: 5px solid #f1ebe4;
  }
}
