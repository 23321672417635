.header__img-1 {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  display: block;
}

.header__title-h1 {
  font-size: 80px;
  color: #b1a8a8;
  font-family: "Cormorant Garamond", serif;
  font-weight: 300;
  text-transform: uppercase;
}
.header__subtitle-h1 {
  font-size: 100px;
}
.header__title {
  position: absolute;
  bottom: 0;
  right: 0;
}
.header__bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.header__text {
  color: $color-main;
  text-transform: uppercase;
  font-size: 22px;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: right;
  letter-spacing: 1.5px;
  text-align: center;
  margin: 0 auto;

  @include md-min {
    font-size: 35px;
    max-width: 80%;
    padding-top: 100px;
  }
}
  
.header__button {
  display: inline-block;
  width: auto;
  border: none;
  background: $color-main;
  color: $color-bg;
  padding: 7px 20px;
  text-transform: uppercase;
  border: 2px solid transparent;
  margin: 0 auto;
  font-weight: 400;

  &:hover {
    background: $color-bg;
    border: 2px solid $color-main;
    color: $color-main;
  }
}
