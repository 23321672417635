.process__item {
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Parisienne', cursive;
}

.process__num {
	font-size: 30px;
	color: $color-dark;
}

.process__icon {
	width: 100px;
	height: 100px;

	.footer & {
		margin-left: -15px;
	}
}

.process__description {
	padding: 30px 0;
	text-align: center;
	width: 90%;
	margin: 0 auto;
}

.process__inner {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	gap: 10px;

	@include md-min {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}

.process__item-main {
	width: 100%;

	@include md-min {
		width: calc(100% / 3);
	}
}
