@import './mixins';
@import './variables';

.menu-desktop {
	display: none;

	@include md-min {
		display: block;
		width: 100%;
		padding: 0;
	}
}

.menu-mobile {
	display: block;
	margin-bottom: 30px;

	@include md-min {
		display: none;
		margin-bottom: 0;
	}
}

.menu__list {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	.menu-mobile & {
		z-index: 11;
		background-color: $color-bg;
		flex-direction: column;
		position: absolute;
		left: 0;
		right: 0;
		height: calc(100vh - 150px);
		justify-content: flex-start;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.3s ease, visibility 0.3s ease;
	}

	.menu-mobile.isOpen & {
		opacity: 1;
		visibility: visible;
		margin-top: 27px;
	}
}

.menu__list--link {
	font-size: 20px;
	display: block;
	width: 100%;
	text-transform: uppercase;
	padding: 20px 0;

	@include md-max {
		font-size: 14px;
	}

	&:hover {
		@include md-min {
			text-decoration: underline;
			text-underline-offset: 5px;
			text-decoration-thickness: 1px;
		}
	}
}

.menu__list-item {
	.menu-mobile & {
		padding: 25px 0;
		border-bottom: 1px solid $color-dark;
		width: calc(100% - 40px);
		margin: 0 auto;
		text-align: center;
	}
}

.menu__logo {
	background-repeat: no-repeat;
	background-size: contain;
	min-width: 300px;
	min-height: 300px;
	background-image: url('../assets/images/logo.png');
	background-position: center;
}

.menu-mobile__logo {
	background-repeat: no-repeat;
	background-size: contain;
	width: 150px;
	height: 150px;
	background-image: url('../assets/images/logo.png');
	margin: 0 auto;

	@include md-min {
		width: 150px;
		height: 150px;
	}
}

.menu-mobile__inner {
	display: flex;
	justify-content: space-between;
	align-content: center;
	width: 80%;
	margin-left: auto;
	margin-right: 0;
}

.hamburger-react {
	z-index: 111 !important;
	position: absolute !important;
	top: 12px;
	right: 20px;

	& > div {
		background: $color-main !important;
	}
}

.menu__list--first {
	@include md-min {
		width: 180px;
	}
}
