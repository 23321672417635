@import './mixins';
@import './variables';

*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	scroll-padding-top: 200px;
}

.App {
	max-width: 1200px;
	margin: 0 auto;
}

body {
	background-color: $color-bg;
	font-family: 'Work Sans', sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 12px;
	overflow-x: hidden;

	@include md-min {
		font-size: 16px;
	}
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	text-size-adjust: none;
}

li {
	list-style-type: none;
	list-style: none;
}

input {
	border-radius: 0;
	outline: none;
}

a {
	text-decoration: none;
}

.wrapper {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0 20px;

	@include xl-min {
		padding: 0;
	}
}

img {
	width: 100%;
	display: block;
}

button {
	cursor: pointer;
	font-size: 12px;

	@include md-min {
		font-size: 16px;
	}
}

.section {
	margin-bottom: 70px;

	@include md-min {
		margin-bottom: 100px;
	}

	&.instagram {
		margin-bottom: 80px;

		@include md-min {
			margin-bottom: 115px;
		}
	}
}

.title {
	font-family: 'Parisienne', cursive;
	font-size: 30px;
	padding-bottom: 70px;
	font-weight: lighter;
	margin: 0 auto;
	color: $color-main;

	@include md-min {
		font-size: 50px;
		padding-bottom: 100px;
	}

	&.title-small {
		@include md-min {
			font-size: 40px;
		}
	}
}

a {
	color: unset;
}
